import { createBreakpoints } from '@mui/system';
import merge from 'lodash/merge';

import createTheme from './base/createTheme';
import createCardVariants from './variants/createCardVariants';
import createCollectionVariants from './variants/createCollectionVariants';
import createFormMarketoEmbedVariants from './variants/createFormMarketoEmbedVariants';
import createHeroVariants from './variants/createHeroVariants';
import createSectionVariants from './variants/createSectionVariants';
import createTextVariants from './variants/createTextVariants';

// https://v5.mui.com/material-ui/customization/palette/#typescript
// https://v5.mui.com/material-ui/customization/palette/#typescript-2
declare module '@mui/material/styles/createPalette' {
  // TODO: Replace additional background color variants with a custom palette (?)
  export interface TypeBackground {
    light: string;
    dark: string;
  }
}

const breakpointValues = {
  xs: 0,
  sm: 684,
  md: 768,
  lg: 1024,
  xl: 1440,
};

// TODO: createBreakpoints is considered private API in MUI v5
const breakpoints = createBreakpoints({
  values: breakpointValues,
});

// These are provided as variables and used by custom color palettes in case we
// want to change them slightly later.
const WHITE = '#FFFFFF';
const BLACK = '#000000';

/**
 * Default theme
 *
 * Default MUI theme viewer: https://mui.com/material-ui/customization/default-theme
 * Design: https://www.figma.com/design/IZuKGumJL0GD2C6jxkLnlg/Brand-2.0-Mockups?node-id=10206-1557&node-type=frame&t=K429rah2rhnWTtpL-0
 */
const defaultTheme = {
  spacing: 8,
  breakpoints: {
    values: breakpointValues,
  },

  /**
   * Typography
   *
   * Designs: https://www.figma.com/design/IZuKGumJL0GD2C6jxkLnlg/Brand-2.0-Mockups?node-id=10206-1557&t=ZdoKlMpIkyqDkXHP-0
   * Customization: https://v5.mui.com/material-ui/react-typography/#customization
   */
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 22,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,

    // https://v5.mui.com/material-ui/customization/typography/#variants
    h1: {
      fontSize: 68,
      fontWeight: 700, // 300 for light version
      lineHeight: 1.2,
      [breakpoints.down('lg')]: {
        fontSize: 50,
      },
      [breakpoints.down('md')]: {
        fontSize: 42,
      },
    },
    h2: {
      fontSize: 46,
      fontWeight: 300, // 700 for bold version
      lineHeight: 'normal',
      [breakpoints.down('lg')]: {
        fontSize: 36,
      },
      [breakpoints.down('md')]: {
        fontSize: 26,
      },
    },
    h3: {
      fontSize: 28,
      lineHeight: 'normal', // TODO: 110% in Figma
      fontWeight: 600, // 300 for light version
      [breakpoints.down('lg')]: {
        fontSize: 24,
      },
      [breakpoints.down('md')]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 24,
      lineHeight: 1.4, // TODO: 120% in Figma
      fontWeight: 300,
      // letterSpacing: 5, // TODO: 5px in Figma
      [breakpoints.down('lg')]: {
        fontSize: 22,
      },
      [breakpoints.down('md')]: {
        fontSize: 20, // TODO: 18 in Figma
      },
    },
    h5: {
      fontSize: 16,
      lineHeight: 'normal',
      fontWeight: 600,
      // letterSpacing: 0, // TODO: 0 in Figma
      [breakpoints.down('lg')]: {
        fontSize: 14,
      },
      [breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    // TODO: Adjust to match Figma
    h6: {
      fontSize: 38,
      lineHeight: 1,
      fontWeight: 600,
    },
    body1: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 300,
      // letterSpacing: 5, // TODO: 5px in Figma
      [breakpoints.down('lg')]: {
        fontSize: 18,
      },
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    // TODO: Where is it used? Make responsive
    body2: {
      fontSize: 18,
    },
    // TODO: Adjust to match Figma. Make responsive
    button: {
      fontSize: 20,
    },
  },

  /**
   * Color variants (tokens) defined by MUI:
   * - main
   * - light (calculated automatically if not provided)
   * - dark (calculated automatically if not provided)
   * - contrastText (calculated automatically if not provided)
   *
   * Main palette variants defined by MUI:
   * - primary
   * - secondary
   * - error
   * - warning
   * - info
   * - success
   *
   * Ancillary palette variants defined by MUI (they don't have the default 4 color tokens):
   * - common (white, black)
   * - grey (shades)
   * - text (primary, secondary, disabled)
   * - background (paper, default)
   * - actions ()
   * - divider
   *
   * Designs: https://www.figma.com/design/IZuKGumJL0GD2C6jxkLnlg/Brand-2.0-Mockups?node-id=10212-1645&t=yxCbOTfSlcwxhTYw-0
   * Customizations: https://v5.mui.com/material-ui/customization/palette/
   * Palette generator: https://m2.material.io/inline-tools/color/
   */
  palette: {
    /** Main palette variants */
    // TODO: Replace primary with secondary, as it's more common (?)
    // Black background with white text
    primary: {
      main: BLACK,
      contrastText: WHITE,
      light: '#F3F0F0',
      dark: '#444444',
    },

    // White background with black text (inverse of primary)
    secondary: {
      main: WHITE,
      contrastText: BLACK,
      light: '#F3F0F0',
      dark: '#444444',
    },

    teal: {
      main: '#00CFB8',
      light: '#ADEAE0',
      dark: '#00b595',
      contrastText: BLACK,
    },

    blue: {
      main: '#1D223D',
      contrastText: WHITE,
    },

    // By default it's the same as the "blue" palette, but it can be
    // overwritten by Page#colorScheme field in Contentful.
    product: {
      main: '#1D223D',
      contrastText: WHITE,
    },

    /** Ancillary palette variants */
    // TODO: Could we just use the default MUI grey palette?
    grey: {
      100: '#F3F0F0',
      200: '#C4C4C4',
      300: '#B0B0B0',
      400: '#7C8184',
      600: '#444444',
      900: '#000000',
    },

    background: {
      default: WHITE,
      paper: WHITE,
      // TODO: Replace additional background color variants with a custom palette (?)
      dark: '#1D223D',
      light: '#00CFB8',
    },

    // TODO: The secondary color doesn't seem to make sense
    text: {
      primary: BLACK,
      secondary: '#1D223D',
    },

    // Used e.g. by top navigation links
    action: {
      active: BLACK,
      hover: '#F6F6F6',
      selected: 'rgba(196,196,196,0.15)',
    },

    // Used e.g. by Marketo form for invalid fields
    error: {
      main: '#C94847',
    },
  },
} as const;

export const schemes = {
  // These keys need to match the Page#colorScheme field options in Contentful
  'Product - Mobile Apps': {
    palette: {
      product: {
        main: '#778DEB',
        contrastText: BLACK,
      },
    },
  },
  'Product - Digital Advertising': {
    palette: {
      product: {
        main: '#C665FC',
        contrastText: BLACK,
      },
    },
  },
  'Product - Audiences': {
    palette: {
      product: {
        main: '#FF826D',
        contrastText: BLACK,
      },
    },
  },
  'Product - Web Insights': {
    palette: {
      product: {
        main: '#22D07D',
        contrastText: BLACK,
      },
    },
  },
} as const;

const createSchemeTheme = (schemeKey: keyof typeof schemes) => {
  // Theme like object
  const scheme = schemes[schemeKey];

  // If scheme is not found, return default theme
  // @ts-expect-error Fix this
  const baseSchemeTheme = createTheme(merge(scheme ? { scheme: schemeKey } : {}, defaultTheme, scheme));

  // All theme customizations go in this function to support generating on the fly
  const schemeTheme = createTheme(
    merge({}, baseSchemeTheme, {
      // Inject creator function to use from ContentModule
      createSchemeTheme,

      typography: {
        h3: {
          color: baseSchemeTheme.palette.secondary.contrastText,
        },
        body1: {
          color: baseSchemeTheme.palette.secondary.contrastText,
        },
      },

      // Theme customizations
      components: {
        Card: {
          variants: createCardVariants(baseSchemeTheme),
        },

        Hero: {
          defaultProps: {
            disableGutters: false,
          },
          variants: createHeroVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              // Anti-Flicker
              transition: 'opacity .3s, background-color .3s',
              transitionDelay: '.2s',
              div: {
                transition: 'opacity .3s, background-color .3s',
                transitionDelay: '.2s',
              },
              '.async-hide &': {
                backgroundColor: 'transparent',
                div: {
                  transition: '0s',
                  transitionDelay: '0s',
                  opacity: '0 !important',
                },
              },
              '&': {
                paddingTop: { xs: 0, md: baseSchemeTheme.spacing(4) },
                paddingBottom: { xs: 0, md: baseSchemeTheme.spacing(4) },
              },
              '& h1': {
                textAlign: 'left',
                color: baseSchemeTheme.palette.secondary.main,
                fontWeight: 300,
              },
              '& h2': {
                textAlign: 'left',
                color: baseSchemeTheme.palette.secondary.main,
                fontWeight: 'bold',
                fontSize: 68,
              },
              img: {
                width: '100%',
              },
              '& p': {
                color: baseSchemeTheme.palette.secondary.main,
                fontSize: 28,
                fontWeight: 300,
                lineHeight: '122%',
                textAlign: 'left',
              },
              '& [class*="Text-root"]': {
                paddingTop: baseSchemeTheme.spacing(2),
              },
              '& .MuiContainer-root': {
                [baseSchemeTheme.breakpoints.down('lg')]: {
                  '& h1': {
                    fontSize: 46,
                    lineHeight: '46.5px',
                  },
                  '& h2': {
                    fontSize: 46,
                    lineHeight: '46.5px',
                  },
                  '& p': {
                    fontSize: 20,
                  },
                },
              },
            },
            actionsRoot: {
              [baseSchemeTheme.breakpoints.down('md')]: {
                justifyContent: 'space-between',
                flexDirection: 'column',
              },
            },
          },
        },

        Collection: {
          variants: createCollectionVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              '& [class*="Collection-introText"]': {
                // Override padding being added to all texts
                '& [class*="MuiTypography-root"]': {
                  paddingBottom: 0,
                },
              },
            },
          },
        },

        Media: {
          defaultProps: {
            disableInlineSVG: true,
          },
        },

        Header: {
          height: 64,
          styleOverrides: {
            root: {
              backgroundColor: baseSchemeTheme.palette.secondary.main,
              '& img': {
                width: 180,
                height: 'auto',
              },
            },
            contentContainer: {
              width: '100%',
              // Match the styles from MuiContainer below
              maxWidth: baseSchemeTheme.breakpoints.values.xl,
              margin: 'auto',
              paddingLeft: baseSchemeTheme.spacing(4),
              paddingRight: baseSchemeTheme.spacing(4),
              [baseSchemeTheme.breakpoints.up('sm')]: {
                paddingLeft: baseSchemeTheme.spacing(6),
                paddingRight: baseSchemeTheme.spacing(6),
              },
              [baseSchemeTheme.breakpoints.up('lg')]: {
                paddingLeft: baseSchemeTheme.spacing(10),
                paddingRight: baseSchemeTheme.spacing(10),
              },
              height: 64,
              '& .MuiLink-root': {
                display: 'flex',
                alignItems: 'center',
                padding: 0,
              },
              '& svg[class*="Header-logo"]': {
                maxHeight: 36,
              },
              '& img[class*="Header-logo"]': {
                height: 33,
                width: 'auto',
              },
            },
          },
        },

        Section: {
          variants: createSectionVariants(baseSchemeTheme),
        },

        Text: {
          variants: createTextVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              '& [class*="MuiTypography-root"]': {
                paddingBottom: baseSchemeTheme.spacing(2),
              },
              [baseSchemeTheme.breakpoints.down('sm')]: {
                '& h2': {
                  fontSize: 39,
                },
              },
              // Removes extra <br> tag being added by Contentful
              '& > br:last-child': {
                display: 'none',
              },
              '& > hr:first-of-type': {
                backgroundColor: baseSchemeTheme.palette.text.primary,
                margin: 0,
                marginBottom: baseSchemeTheme.spacing(3),
                width: 36,
                height: 2,
                border: 'none',
              },
              '& li > p[class*="MuiTypography-root"]': {
                marginBottom: baseSchemeTheme.spacing(2),
                paddingBottom: 0,
              },

              'tr:first-of-type .MuiTypography-root': {
                fontWeight: 800,
                wordBreak: 'keep-all',
              },
              table: {
                width: '100%',
                border: '1px solid',
                borderCollapse: 'collapse',
                td: {
                  border: '1px solid',
                  padding: '8px',
                  wordBreak: 'break-word',
                  '*': {
                    padding: '0!important',
                    display: 'inline',
                  },
                },
              },
            },
          },
        },

        FormMarketoEmbed: {
          variants: createFormMarketoEmbedVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              '.marketo-blog-marketo[class*="-FormMarketoEmbed-formWrapper"]': {
                'form.mktoForm[class*="-FormMarketoEmbed-form"]': {
                  '.mktoFormRow': {
                    'select.mktoField': {
                      color: `${baseSchemeTheme.palette.secondary.main} !important`,
                      fontSize: '1.4rem !important',
                    },
                    '.mktoFieldWrap[class*="-select"]:after': {
                      top: 31,
                    },
                    '.mktoFieldWrap:has(.privacy)': {
                      color: `${baseSchemeTheme.palette.common.black} !important`,
                      backgroundColor: 'transparent',
                    },
                  },
                  '.mktoButtonRow .mktoButtonWrap': {
                    display: 'flex',
                    justifyContent: 'center',
                    'button.mktoButton': {
                      color: `${baseSchemeTheme.palette.common.white} !important`,
                      backgroundColor: `${baseSchemeTheme.palette.primary.main} !important`,
                      width: 'fit-content',
                      minWidth: 290,
                      height: 56,
                      fontSize: '18px !important',
                      letterSpacing: 5,
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      ':hover': {
                        backgroundColor: `${baseSchemeTheme.palette.primary.main}80 !important`,
                      },
                    },
                  },
                },
              },
            },
            form: {
              padding: `${baseSchemeTheme.spacing(2, 1)} !important`,
              fontFamily: `${baseSchemeTheme.typography.fontFamily} !important`,
              fontWeight: 500,
              color: `${baseSchemeTheme.palette.secondary.contrastText} !important`,
              '& .mktoFormRow:not(:has(input[type=hidden]))': {
                marginBottom: baseSchemeTheme.spacing(1),
              },
              '& .mktoFieldWrap': {
                borderRadius: `${baseSchemeTheme.spacing(1)} !important`,
                borderWidth: '2px !important',
              },
              '& .mktoButtonWrap': {
                '& button': {
                  backgroundColor: `${baseSchemeTheme.palette.secondary.main} !important`,
                },
              },
              '& input': {
                height: 'auto !important',
                fontSize: '23px !important',
                '&::placeholder': {
                  opacity: '1 !important',
                },
              },
              '& [class*="-select"]': {
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  zIndex: -1,
                  top: 24,
                  right: 22,
                  width: 0,
                  height: 0,
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent',
                  borderTop: '16px solid white',
                },
              },
              '& select': {
                width: '100% !important',
                height: '65px !important',
                padding: '0 10px !important',
                backgroundColor: 'transparent',
                border: 0,
                color: 'white !important',
                fontFamily: '"Source Sans Pro", sans-serif !important',
                fontSize: '22px !important',
                fontWeight: 500,
                letterSpacing: '0.5px',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                '& option': {
                  color: 'black !important',
                  fontFamily: '"Source Sans Pro", sans-serif !important',
                },
              },
              '& button': {
                height: 70,
                borderRadius: `${baseSchemeTheme.spacing(1)} !important`,
                boxShadow: 'none !important',
                fontSize: '23px !important',
                fontWeight: 500,
                [baseSchemeTheme.breakpoints.up('lg')]: {
                  fontSize: '35px !important',
                },
              },
            },
          },
        },

        // MUI overrides
        MuiContainer: {
          styleOverrides: {
            root: {
              paddingLeft: baseSchemeTheme.spacing(4),
              paddingRight: baseSchemeTheme.spacing(4),
              [baseSchemeTheme.breakpoints.up('sm')]: {
                paddingLeft: baseSchemeTheme.spacing(6),
                paddingRight: baseSchemeTheme.spacing(6),
              },
              [baseSchemeTheme.breakpoints.up('lg')]: {
                paddingLeft: baseSchemeTheme.spacing(10),
                paddingRight: baseSchemeTheme.spacing(10),
              },
              '& .MuiContainer-disableGutters': {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          },
        },

        MuiTypography: {
          styleOverrides: {
            root: {
              transition: 'opacity .2s',

              '.async-hide &': {
                opacity: '0 !important',
              },

              // Treat new-line character as <br>
              whiteSpace: 'pre-wrap',
            },
          },
        },

        MuiButton: {
          variants: [
            {
              props: {
                variant: 'nav',
              },
              style: {
                minWidth: 'auto',
                height: 'auto',
                marginTop: baseSchemeTheme.spacing(1),
                marginBottom: baseSchemeTheme.spacing(1),
                paddingTop: baseSchemeTheme.spacing(0.25),
                paddingBottom: baseSchemeTheme.spacing(0.25),
                borderRadius: 6,
                fontSize: 16,
                fontWeight: 600,
                ':disabled': {
                  backgroundColor: baseSchemeTheme.palette.action.hover,
                  color: baseSchemeTheme.palette.action.active,
                },
                ':hover': {
                  backgroundColor: baseSchemeTheme.palette.action.hover,
                  color: baseSchemeTheme.palette.action.active,
                },
              },
            },
            {
              props: {
                variant: 'language',
              },
              style: {
                color: baseSchemeTheme.palette.secondary.main,
                fontSize: 15,
                fontWeight: 600,
                border: `2px solid ${baseSchemeTheme.palette.secondary.main}`,
                height: 'auto',
                minWidth: 'auto',
                width: 240,
                [baseSchemeTheme.breakpoints.down('md')]: {
                  borderRadius: 10,
                },
              },
            },
          ],
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              minWidth: 290,
              height: 56,
              borderRadius: 56 / 2,
              fontWeight: 600,
              fontSize: 18,
              textTransform: 'none',
              [baseSchemeTheme.breakpoints.down('md')]: {
                width: '100%',
                minWidth: 'auto',
                height: 43,
                borderRadius: 43 / 2,
                marginBottom: baseSchemeTheme.spacing(2),
                fontSize: 16,
              },
            },
            // White text on black background
            contained: {
              backgroundColor: baseSchemeTheme.palette.primary.main,
              color: baseSchemeTheme.palette.primary.contrastText,
              // Change the background color on hover
              '&:hover': {
                backgroundColor: baseSchemeTheme.palette.grey[600],
              },
            },
            // Black text on white background with black outline
            outlined: {
              // It's inverse of the contained variant
              backgroundColor: baseSchemeTheme.palette.secondary.main,
              color: baseSchemeTheme.palette.secondary.contrastText,
              borderColor: baseSchemeTheme.palette.secondary.contrastText,
              // Change the text and outline color on hover
              '&:hover': {
                backgroundColor: baseSchemeTheme.palette.secondary.main,
                color: baseSchemeTheme.palette.grey[600],
                borderColor: baseSchemeTheme.palette.grey[600],
              },
            },
          },
        },

        MuiGrid: {
          styleOverrides: {
            root: {
              [baseSchemeTheme.breakpoints.down('md')]: {
                '&.MuiGrid-spacing-xs-15': {
                  marginTop: baseSchemeTheme.spacing(-5),
                  '> .MuiGrid-item': {
                    paddingTop: baseSchemeTheme.spacing(5),
                  },
                },
              },
            },
          },
        },

        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: baseSchemeTheme.palette.primary.light,
            },
          },
        },

        MuiList: {
          defaultProps: {
            dense: true,
            disablePadding: true,
          },
        },

        MuiListSubheader: {
          styleOverrides: {
            root: {
              position: 'relative',
              paddingLeft: baseSchemeTheme.spacing(5),
              color: baseSchemeTheme.palette.text.primary,
              fontSize: 24,
              fontWeight: 600,
              lineHeight: baseSchemeTheme.spacing(4),
            },
          },
        },

        MuiListItem: {
          styleOverrides: {
            root: {
              padding: baseSchemeTheme.spacing(0.5, 0),
            },
          },
        },

        MuiListItemButton: {
          styleOverrides: {
            root: {
              padding: baseSchemeTheme.spacing(0, 5),
              [baseSchemeTheme.breakpoints.down('md')]: {
                paddingLeft: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          },
        },

        MuiListItemText: {
          styleOverrides: {
            primary: {
              color: baseSchemeTheme.palette.secondary.contrastText,
              fontSize: 14,
              fontWeight: 700,
              [baseSchemeTheme.breakpoints.down('lg')]: {
                color: baseSchemeTheme.palette.primary.contrastText,
                fontSize: 15,
                fontWeight: 400,
              },
            },
            secondary: {
              color: baseSchemeTheme.palette.secondary.contrastText,
              fontSize: 15,
              fontWeight: 400,
            },
          },
        },

        MuiListItemAvatar: {
          styleOverrides: {
            root: {
              minWidth: 52,
              '& img': {
                objectFit: 'cover',
                width: '100%',
              },
              '& .MuiAvatar-root': {
                height: 34,
                width: 34,
              },
              [baseSchemeTheme.breakpoints.down('md')]: {
                minWidth: baseSchemeTheme.spacing(3),
              },
            },
          },
        },
      },
    }),
  );

  return schemeTheme;
};

// @ts-expect-error Fix this. This functions expects a schemeKey, but it's not passed here...
const theme = createSchemeTheme();

export default theme;
